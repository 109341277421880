Lyte.Component.register("crux-file-size-component", {
_template:"<template tag-name=\"crux-file-size-component\"> <div class=\"cxBoxWithRightIcon cxFileSizeCompContainer\"> <lyte-number class=\"cxBoxInput cxBoxLeftContent\" lt-prop-tabindex=\"{{if(cxPropTabIndex,cxPropTabIndex,cxPropTabindex)}}\" lt-prop-value=\"{{cxPropInputValue}}\" on-value-change=\"{{method('onInputValueChange')}}\" on-focus=\"{{method('onFocusInput')}}\" on-blur=\"{{method('onBlurInput')}}\"></lyte-number> <lyte-dropdown class=\"cxBoxDropdown cxFileSizeDropdown\" lt-prop-tabindex=\"{{if(cxPropTabIndex,cxPropTabIndex,cxPropTabindex)}}\" lt-prop-user-value=\"name\" lt-prop-system-value=\"value\" lt-prop-options=\"{{cxPropOptions}}\" lt-prop-selected=\"{{cxPropSelectedOption}}\" on-option-selected=\"{{method('onOptionSelected')}}\" on-show=\"{{method('onShow')}}\" on-hide=\"{{method('onHide')}}\" onfocusout=\"{{action('onBlurDropdownCallback',this)}}\"></lyte-dropdown> </div> <template is=\"if\" value=\"{{isError}}\"><template case=\"true\"> <crux-error-message error-message=\"{{cxPropErrorMessage}}\" cx-prop-error-yield=\"{{cxPropErrorYield}}\" cx-prop-error-zcqa-prefix=\"{{cxPropErrorZcqaPrefix}}\" cx-prop-field=\"{{cxPropField}}\" cx-prop-error-zcqa-suffix=\"{{cxPropErrorZcqaSuffix}}\" class=\"{{cxPropErrorClass}}\" cx-prop-error-span-class=\"{{cxPropErrorSpanClass}}\" cx-prop-aria-error-properties=\"{{cxPropAriaErrorProperties}}\" cx-prop-error-unescape-arguments=\"{{cxPropErrorUnescapeArguments}}\" cx-prop-id=\"{{cxPropId}}\"> <template is=\"registerYield\" yield-name=\"errorYield\"><lyte-yield yield-name=\"errorYield\" error-message=\"{{cxPropErrorMessage}}\" cx-field=\"{{cxPropField}}\"></lyte-yield></template> </crux-error-message> </template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1,1]},{"type":"componentDynamic","position":[1,1]},{"type":"attr","position":[1,3]},{"type":"componentDynamic","position":[1,3]},{"type":"attr","position":[3]},{"type":"if","position":[3],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[0]},{"type":"insertYield","position":[0]}]},{"type":"componentDynamic","position":[1]}]}},"default":{}}],
_observedAttributes :["cxPropValue","cxPropInputValue","cxPropOptions","cxPropField","cxPropId","cxPropSelectedOption","cxPropErrorMessage","cxPropDecimalAllowed","cxPropErrorUnescapeArguments","cxPropTabIndex","cxPropTabindex","cxPropAriaErrorProperties"],
	data : function(){
		return {
			cxPropValue : Lyte.attr('number', {default : 100000000000}),
			// cxPropOrigValue : Lyte.attr('number'),
			cxPropInputValue : Lyte.attr('string'),
			cxPropOptions : Lyte.attr('array', {default : [
				{
					name : _cruxUtils.getI18n('bytes'),
					value : '0'
				},{
					name : _cruxUtils.getI18n('KB'),
					value : '1'
				},{
					name : _cruxUtils.getI18n('MB'),
					value : '2'
				}
			]}),
			cxPropField : Lyte.attr('object', {default : {}}),
			cxPropId: Lyte.attr("string", {"default": ""}), //NO i18n
			cxPropSelectedOption : Lyte.attr('string'),
			cxPropErrorMessage : Lyte.attr("string", {default : ""}),//No I18n
			cxPropDecimalAllowed : Lyte.attr('boolean', {default : true}),
			cxPropErrorUnescapeArguments : Lyte.attr('array'),
			/**
			 * It sets tab index for input
			 * @componentProperty { string } cxPropTabIndex
			 * @author mariswaran.sv
			 * @version 1.0.0
			 */
			cxPropTabIndex : Lyte.attr("string"), //No I18n
			/**
			 * It sets tab index for input
			 * @componentProperty { string } cxPropTabindex
			 * @author mariswaran.sv
			 * @version 1.0.0
			 */
			cxPropTabindex : Lyte.attr("string"), //No I18n
			/**
			 * @componentProperty { object } cxPropAriaErrorProperties = {'ariaErrorIcon': true/false, 'ariaErrorColor' : 'string'}
			 * @author mariswaran.sv
			 * @version 1.0.0
			 * property to set error icon and error color
			 */
			cxPropAriaErrorProperties : Lyte.attr('object')
		};
	},
	getValue : function(){
		return this.$node.cxProp('value');
	},
	validate : function(){
		var val = this.getData('cxPropInputValue');
		var field = this.getData("cxPropField");//No I18n
		var check;
		this.setData("cxPropErrorMessage", "");//No I18n
		if(!val || val === ''){
			this.setData('isError', true);
			this.setData("cxPropErrorMessage", _cruxUtils.getI18n("crm.custombutton.valid.weburl.check"));//No I18n
			return false;
		}else if(this.getData("cxPropFrom") === "create"){
			var res = this.validateNumberField(val, field, this.data.maxlength, this.data.cxPropMaxvalue, this.data.cxPropMinvalue);
			var lyteNumberComp = this.$node.querySelector("lyte-number");
			var lyteInputComp = this.$node.querySelector("lyte-input");
			if(res === false && !this.data.cxPropPreventFocusOnError){
				if(lyteInputComp){
					lyteInputComp.focus();
				}else if(lyteNumberComp){
					lyteNumberComp.focus();
				}
				// lyteInputComp ? lyteInputComp.focus() : (lyteNumberComp ? lyteNumberComp.focus() : "");
				// this.$node.querySelector("lyte-input") ? this.$node.querySelector("lyte-input").focus() : "";//No I18n
			}
			return res;
		}
		else if((!val || isNaN(parseInt(val))) && !this.data.cxPropIgnoreEmptyValue){
			this.showAlert(_cruxUtils.getI18n("crm.field.valid.check", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			this.$node.querySelector("input").focus();//No I18n
			return false;
		}else if( (!this.getData("cxPropAllowNegativeValue") && ( val.indexOf("-") !== -1 || val.indexOf("+") !== -1 ) ) || (!this.data.cxPropDecimalAllowed && val.indexOf(".") !== -1) ){ //no i18n
				this.showAlert(_cruxUtils.getI18n("crm.mb.field.common.splc"));//No I18n
				this.$node.querySelector("input").focus();//No I18n
				return false;

		}else if(field.data_type === "autonumber" || field.data_type === "bigint"){ //No I18n
			check =  field.data_type === "bigint" ? this.isValidInteger(val) : true;
			if(val.indexOf(".") > -1 || !check){
				this.showAlert(_cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
				this.$node.querySelector("input").focus();//No I18n
				return false;
			}
		}
		else if(field.data_type !== "integer"){ //no i18n
			check = this.isValidDecimal(val);
			if(check){
				check = this.decimalLengthCheck(val);
				if(!check){
					this.showAlert(_cruxUtils.getI18n("crm.field.valid.decimal.check2", Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label), field.decimal_place));//No I18n
					this.$node.querySelector("input").focus();//No I18n
				}
			}
			else{
				this.showAlert(_cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			}
			return check;
		}else if(this.data.cxPropFrom === 'criteria' || this.data.cxPropFrom === 'filter'){ //no i18n
			check = this.isValidDecimal(val);
			if(check){
				check = this.decimalLengthCheck(val,{decimal_place : typeof this.data.cxPropField.decimal_place === 'undefined' || this.data.cxPropField.decimal_place === 'null' ? 2 :  this.data.cxPropField.decimal_place});
			}
			if(!check){
				this.showAlert(_cruxUtils.getI18n('crm.field.valid.check', Lyte.Component.registeredHelpers.cruxEncodeHTML(field.field_label)));//No I18n
			}
			return check;
		}
		return true;
	},
	actions : {
		// Functions for event handling
	},
	methods : {
		// Functions which can be used as callback in the component.
		onInputValueChange : function(val){
			var newVal = val.newValue;
			this.setData('cxPropInputValue', newVal);
			this.byteConversion();
			this.validate();
		},
		onOptionSelected : function(event, sel){
			this.setData('cxPropSelectedOption', sel);
			this.byteConversion();
		},
		onShow : function(event, comp){
			this.$node.querySelector('.cxFileSizeCompContainer').classList.add("cxBoxInputFocused");
			if(this.getMethods('onElementDropdownOpen')){
				this.executeMethod('onElementDropdownOpen', this, event, comp);
			}
		},
		onFocusInput : function(){
			this.$node.querySelector('.cxFileSizeCompContainer').classList.add("cxBoxInputFocused");
		},
		onHide : function(event, comp){
			this.$node.querySelector('.cxFileSizeCompContainer').classList.remove("cxBoxInputFocused");
			if(this.getMethods('onElementDropdownClose')){
				this.executeMethod('onElementDropdownClose', this, event, comp);
			}
		},
		onBlurInput : function(event, element){
			this.$node.querySelector('.cxFileSizeCompContainer').classList.remove("cxBoxInputFocused");
			if(this.getMethods("onBlur")){
				/**
				 * @method onBlur
				 * @author anuja.manoharan
				 * @version 1.0.0
				 * @param { * } event
				 * @param { * } element
				 * This method is called whenever input is blured
				 */
				this.executeMethod("onBlur", event, element);
			}
		},
		onBlurDropdownCallback : function(lyteDropdown, event, element){
			if(!lyteDropdown.ltProp('isOpen') && this.getMethods("onBlur")){
				/**
				 * @method onBlur
				 * @author anuja.manoharan
				 * @version 1.0.0
				 * @param { * } event
				 * @param { * } element
				 * This method is called whenever input is blured
				 */
				this.executeMethod("onBlur", event, element);
			}
		}
	},
	observeErrorMessage : function(){
		this.setData("isError", this.data.cxPropErrorMessage === "" ? false : true);//No I18n
	}.observes("cxPropErrorMessage"),//No I18n
	observeMandatory : function(){
		this.observeMandatoryMixin("lyte-number");//No I18n
	}.observes("cxPropField.required", "cxPropMandatory", "cxPropFrom").on("didConnect"),//No I18n
	observeIsError : function(){
		// if(this.getData("cxPropFrom") == "create" && this.$node.querySelector("lyte-checkbox")){
			if(this.getData("isError")){
				this.$node.querySelector(".cxFileSizeCompContainer").classList.add("cxErrorBoxWithRightIcon");//No I18n
			}
			else{
				this.$node.querySelector(".cxFileSizeCompContainer").classList.remove("cxErrorBoxWithRightIcon");//No I18n
			}
		// }
	}.observes("isError", "lyteViewPort").on("didConnect"),//No I18n
	observeIsErrorForAria : function(){
		if(this.data.cxPropAria){
			this.observeIsErrorForAriaMixin();//No I18n
		}
	}.observes('isError', 'cxPropAria'),
	init : function(){
		/*
			Case(for Aria case recruit team) : Need id to select crux-error-message span(which contains error message) to set aria-describedby (read error with label) 
			Set cxPropId if not given
		*/
		if(!this.data.cxPropId){
			this.setData('cxPropId', this.data.cxPropField.id);
		}
	},
	didConnect : function(){
		// this.byteConversion('byte');
		this.setData('cxPropInputValue', this.getData('cxPropValue'));
	},
	byteConversion : function(){
		var inputVal;
		var tempVal;
		var i;
		var conversionValue;
		var compVal = this.$node.cxProp('inputValue');
		if(compVal.includes('.')){
			inputVal = parseFloat(compVal);
		}else{
			inputVal = parseInt(compVal);
		}
		tempVal = inputVal;
		i = parseInt(this.$node.cxProp('selectedOption'));
		while(i>0){
			tempVal = tempVal*1024;
			i--;
		}
		conversionValue = tempVal;
		this.setData('cxPropValue', conversionValue);
	}
}, {mixins : ["crux-element-validation"]});
